<template>
  <div>
    <template v-if="resultData.licence">
      <v-row class="mt-4 mb-8">
        <v-col class="ml-4">
          <span class="overline">
            Driver Details
          </span>
          <p>
            <span class="body-2 text--secondary">Name:</span>
            <span> {{ resultData.driver.first_name }} {{ resultData.driver.last_name }}</span>
            <br />
            <span class="body-2 text--secondary">Gender:</span>
            <span> {{ resultData.driver.gender }}</span>
            <br />
            <span class="body-2 text--secondary">Date of birth:</span>
            <span> {{ longIsoDate(resultData.driver.date_of_birth) }}</span>
            <br />
            <span class="body-2 text--secondary">Address:</span>
            <span> {{ addressInfo }}</span>
          </p>
        </v-col>
        <v-divider
          vertical
          inset
        />
        <v-col class="ml-4">
          <span class="overline">
            Licence Details
          </span>
          <p>
            <span class="body-2 text--secondary">Number:</span>
            <span>{{ resultData.driver.licence_number }}</span>
            <br />
            <span class="body-2 text--secondary">Type:</span>
            <span> {{ resultData.licence.type }}</span>
            <br />
            <span class="body-2 text--secondary">Status:</span>
            <span>
              {{ resultData.licence.status }}
              <span v-if="resultData.licence.status_qualifier">
                ({{ resultData.licence.status_qualifier }})
              </span>
            </span>
            <template v-if="resultData.token">
              <br />
              <span class="body-2 text--secondary">Valid from:</span>
              <span> {{ longIsoDate(resultData.token.valid_from_date) }}</span>
              <br />
              <span class="body-2 text--secondary">Valid to:</span>
              <span> {{ longIsoDate(resultData.token.valid_to_date) }}</span>
              <br />
              <span class="body-2 text--secondary">Issue number:</span>
              <span> {{ resultData.token.issue_number }}</span>
            </template>
          </p>
        </v-col>
      </v-row>

      <v-card-title class="headline">
        Endorsements
      </v-card-title>
      <v-data-table
        class="mb-10"
        :headers="endorsementHeaders"
        :items="resultData.endorsements"
        disable-pagination
        hide-default-footer
        dense
      >
        <template #item.offence_date="{ item }">
          {{ longIsoDate(item.offence_date) }}
        </template>
        <template #item.conviction_date="{ item }">
          {{ longIsoDate(item.conviction_date) }}
        </template>
        <template #item.sentence_date="{ item }">
          {{ longIsoDate(item.sentence_date) }}
        </template>
        <template #item.disqualification="{ item }">
          {{ formatDisqualification(item.disqualification) }}
        </template>
        <template #no-data>
          No endorsements.
        </template>
      </v-data-table>

      <v-card-title class="headline">
        Entitlements
      </v-card-title>
      <v-data-table
        class="mb-10"
        :headers="entitlementHeaders"
        :items="resultData.entitlements"
        disable-pagination
        hide-default-footer
        dense
      >
        <template #item.from_date="{ item }">
          {{ longIsoDate(item.from_date) }}
        </template>
        <template #item.expiry_date="{ item }">
          {{ longIsoDate(item.expiry_date) }}
        </template>
        <template #item.restrictions="{ item }">
          {{ formatRestrictions(item.restrictions) }}
        </template>
        <template #no-data>
          No entitlements.
        </template>
      </v-data-table>

      <v-card-title class="headline">
        Tests
      </v-card-title>
      <v-data-table
        class="mb-10"
        :headers="testHeaders"
        :items="resultData.passed_tests"
        disable-pagination
        hide-default-footer
        dense
      >
        <template #item.test_date="{ item }">
          {{ longIsoDate(item.test_date) }}
        </template>
        <template #no-data>
          No tests.
        </template>
      </v-data-table>

      <template v-if="caseActivityDetails.config.include_cpc">
        <v-card-title class="headline">
          CPCs
        </v-card-title>
        <v-data-table
          class="mb-10"
          :headers="cpcHeaders"
          :items="resultData.cpcs"
          disable-pagination
          hide-default-footer
          dense
        >
          <template #item.lgv_valid_to="{ item }">
            {{ longIsoDate(item.lgv_valid_to) }}
          </template>
          <template #item.pcv_valid_to="{ item }">
            {{ longIsoDate(item.pcv_valid_to) }}
          </template>
          <template #no-data>
            No CPCs.
          </template>
        </v-data-table>

        <v-card-title class="headline">
          DQCs
        </v-card-title>
        <v-data-table
          class="mb-10"
          :headers="dqcHeaders"
          :items="resultData.dqcs"
          disable-pagination
          hide-default-footer
          dense
        >
          <template #item.issue_date="{ item }">
            {{ longIsoDate(item.issue_date) }}
          </template>
          <template #no-data>
            No DQCs.
          </template>
        </v-data-table>
      </template>

      <template v-if="caseActivityDetails.config.include_tacho">
        <v-card-title class="headline">
          Tacho Cards
        </v-card-title>
        <v-data-table
          class="mb-10"
          :headers="tachoCardHeaders"
          :items="resultData.tacho_cards"
          disable-pagination
          hide-default-footer
          dense
        >
          <template #item.valid_from="{ item }">
            {{ longIsoDate(item.valid_from) }}
          </template>
          <template #item.valid_to="{ item }">
            {{ longIsoDate(item.valid_to) }}
          </template>
          <template #no-data>
            No tacho cards.
          </template>
        </v-data-table>
      </template>
    </template>
    <template v-else-if="noDriverRecordFound">
      No driver record found.
    </template>
    <template v-else>
      Results not yet available.
    </template>
  </div>
</template>

<script>
import { longIsoDate } from '@/filters';

export default {
  name: 'DriverData',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    endorsementHeaders: [
      { text: 'Description', value: 'offence_legal_literal' },
      { text: 'Code', value: 'offence_code' },
      { text: 'Offence Date', value: 'offence_date' },
      { text: 'Conviction Date', value: 'conviction_date' },
      { text: 'Sentence Date', value: 'sentence_date' },
      { text: 'Disqualifided Pending Sentence', value: 'disqualified_pending_sentence' },
      { text: 'Disqualification', value: 'disqualification' },
      { text: 'Points', value: 'penalty_points' },
    ],
    entitlementHeaders: [
      { text: 'Descriptipon', value: 'category_legal_literal' },
      { text: 'Code', value: 'category_code' },
      { text: 'Type', value: 'category_type' },
      { text: 'From Date', value: 'from_date' },
      { text: 'Expiry Date', value: 'expiry_date' },
      { text: 'Restrictions', value: 'restrictions' },
    ],
    cpcHeaders: [
      { text: 'Large Good Vehicles Valid To', value: 'lgv_valid_to' },
      { text: 'Passenger Carrying Vehicles Valid To', value: 'pcv_valid_to' },
    ],
    dqcHeaders: [
      { text: 'Issue Date', value: 'issue_date' },
    ],
    tachoCardHeaders: [
      { text: 'Number', value: 'number' },
      { text: 'Status', value: 'status' },
      { text: 'Valid From', value: 'valid_from_date' },
      { text: 'Valid To', value: 'expiry_date' },
    ],
    testHeaders: [
      { text: 'Description', value: 'category_legal_literal' },
      { text: 'Code', value: 'category_code' },
      { text: 'Test Date', value: 'test_date' },
      { text: 'Status', value: 'status' },
      { text: 'Automatic Transmission', value: 'with_automatic_transmission' },
    ],
  }),
  computed: {
    resultData() {
      return this.caseActivityDetails.result_data;
    },
    addressInfo() {
      if (this.resultData.driver && this.resultData.driver.address) {
        const { address } = this.resultData.driver;
        return [...address.lines, address.postcode].join(', ');
      }
      return [];
    },
    noDriverRecordFound() {
      return this.resultData.errors && this.resultData.errors.find((e) => e.code === 'ENQ018');
    },
  },
  methods: {
    formatDisqualification(disqualification) {
      if (!disqualification) {
        return '';
      }
      let description = '';
      if (disqualification.for_life) {
        description = 'For life';
      } else {
        description = ['years', 'months', 'days'].filter((key) => key in disqualification).map(
          (key) => `${disqualification[key]} ${key}`,
        ).join(' ');
      }
      if (disqualification.type) {
        description = `${description} (${disqualification.type})`;
      }
      return description;
    },
    formatRestrictions(restrictions) {
      if (!restrictions) {
        return '';
      }
      return restrictions.map((r) => `${r.code} (${r.literal})`).join(', ');
    },
    longIsoDate,
  },
};
</script>

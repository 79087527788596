const parseYotiAddress = (address) => {
  const parsedAddress = {
    country: address.country_iso,
    postcode: address.postal_code ? address.postal_code.toUpperCase() : '',
    town: address.town_city,
  };
  const lines = [
    address.address_line1,
    address.address_line2,
    address.address_line3,
    address.address_line4,
    address.address_line5,
    address.address_line6,
  ].filter(Boolean).filter((l) => l !== address.postal_code).filter((l) => l !== address.town_city);
  if (lines.length === 4) {
    parsedAddress.line_one = lines.slice(0, 2).join(' ');
    parsedAddress.line_two = lines.slice(2, 4).join(' ');
  } else if (lines.length === 3) {
    parsedAddress.line_one = lines.slice(0, 2).join(' ');
    parsedAddress.line_two = lines[2]; // eslint-disable-line prefer-destructuring
  } else if (lines.length === 2) {
    [parsedAddress.line_one, parsedAddress.line_two] = lines;
  } else {
    [parsedAddress.line_one] = lines;
  }
  return parsedAddress;
};

const nameMismatchReasonItems = [
  {
    label: 'Previous name on document(s)',
    value: 'document_previous_name',
  },
  {
    label: 'Shortened name on document(s)',
    value: 'document_short_name',
  },
  {
    label: 'Account name is not Full Legal Name',
    value: 'account_name_incorrect',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export {
  parseYotiAddress,
  nameMismatchReasonItems,
};

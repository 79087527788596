<template>
  <div>
    <v-list v-if="basicDetails.id">
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.title }}</v-list-item-title>
              <v-list-item-subtitle>Title</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.forename }}</v-list-item-title>
              <v-list-item-subtitle>Forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.forename_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.forename_dbs }}</v-list-item-title>
              <v-list-item-subtitle>DBS forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.middlename">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.middlename }}</v-list-item-title>
              <v-list-item-subtitle>Middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.middlename_dbs">
            <v-list-item-content>
              <v-list-item-title>
                {{ basicDetails.middlename_dbs }}
              </v-list-item-title>
              <v-list-item-subtitle>DBS middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.surname }}</v-list-item-title>
              <v-list-item-subtitle>Surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.surname_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.surname_dbs }}</v-list-item-title>
              <v-list-item-subtitle>DBS surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.nationality }}</v-list-item-title>
              <v-list-item-subtitle>Nationality</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.gender }}</v-list-item-title>
              <v-list-item-subtitle>Gender</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ basicDetails.dob }}
              </v-list-item-title>
              <v-list-item-subtitle>Date of birth</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.phone_number }}</v-list-item-title>
              <v-list-item-subtitle>Phone number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.nin">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.nin }}</v-list-item-title>
              <v-list-item-subtitle>NI number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <v-list v-if="birthDetails.id">
      <v-divider class="mt-8" />
      <v-card-subtitle>Birth Details</v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ birthDetails.town }}</v-list-item-title>
              <v-list-item-subtitle>Town</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="birthDetails.town_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ birthDetails.town_dbs }}</v-list-item-title>
              <v-list-item-subtitle>DBS town</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="birthDetails.county">
            <v-list-item-content>
              <v-list-item-title>{{ birthDetails.county }}</v-list-item-title>
              <v-list-item-subtitle>County</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ birthDetails.country }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="birthDetails.country_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ birthDetails.country_dbs }}</v-list-item-title>
              <v-list-item-subtitle>DBS country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <template v-if="otherNames.length">
      <v-divider class="mt-8" />
      <v-card-subtitle>Other names</v-card-subtitle>
      <v-data-table
        :headers="otherNamesHeaders"
        :items="otherNames"
        hide-default-footer
        disable-pagination
        class="ml-4 mr-4"
      >
        <template #item.name="{ item }">
          {{ [item.forename, item.middlename, item.surname].filter(Boolean).join(' ') }}
        </template>
      </v-data-table>
    </template>
    <v-list v-if="passport.id">
      <v-divider class="mt-8" />
      <v-card-subtitle>Passport</v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ passport.number }}</v-list-item-title>
              <v-list-item-subtitle>Number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ passport.country }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="passport.country_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ passport.country_dbs }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ passport.issue_date }}</v-list-item-title>
              <v-list-item-subtitle>Issue date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ passport.expiry_date }}</v-list-item-title>
              <v-list-item-subtitle>Expiry date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <v-list v-if="drivingLicence.id">
      <v-divider class="mt-8" />
      <v-card-subtitle>Driving licence</v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ drivingLicence.number }}</v-list-item-title>
              <v-list-item-subtitle>Number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ drivingLicence.country }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="drivingLicence.country_dbs">
            <v-list-item-content>
              <v-list-item-title>{{ drivingLicence.country_dbs }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ drivingLicence.issue_date }}</v-list-item-title>
              <v-list-item-subtitle>Issue date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ drivingLicence.expiry_date }}</v-list-item-title>
              <v-list-item-subtitle>Expiry date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>

export default {
  name: 'BasicDetails',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    basicDetails: {},
    birthDetails: {},
    passport: {},
    drivingLicence: {},
    otherNames: [],
    otherNamesHeaders: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'From',
        value: 'from_date',
      },
      {
        text: 'To',
        value: 'to_date',
      },
    ],
  }),
  created() {
    this.getBasicDetails();
    this.getBirthDetails();
    this.getOtherNames();
    this.getPassport();
    this.getDrivingLicence();
  },
  methods: {
    async getBasicDetails() {
      const resp = await this.$service.product.caseBasicDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.basicDetails = resp.data;
      }
    },
    async getBirthDetails() {
      const resp = await this.$service.product.caseBirthDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.birthDetails = resp.data;
      }
    },
    async getOtherNames() {
      const resp = await this.$service.product.caseOtherNames.list(
        { params: { case: this.caseActivityDetails.case.id } },
      );
      this.otherNames = resp.data;
    },
    async getPassport() {
      const resp = await this.$service.product.casePassports.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.passport = resp.data;
      }
    },
    async getDrivingLicence() {
      const resp = await this.$service.product.caseDrivingLicences.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.drivingLicence = resp.data;
      }
    },
  },
};
</script>

<template>
  <div v-if="caseActivityDetails.id">
    <v-row v-if="activityData.application_details">
      <v-col
        v-if="activityData.applicant_details"
      >
        <span
          class="overline"
        >
          Applicant Details
        </span>
        <p>
          <span class="body-2 text--secondary">Name:</span>
          <span> {{ applicantName }}</span>
          <br>
          <span class="body-2 text--secondary">Date of birth:</span>
          <span> {{ activityData.applicant_details.date_of_birth }}</span>
          <br>
          <span class="body-2 text--secondary">Place of birth:</span>
          <span> {{ activityData.applicant_details.place_of_birth }}</span>
          <br>
        </p>
      </v-col>
      <v-divider
        v-if="activityData.applicant_details"
        vertical
        inset
      />
      <v-col v-if="activityData.application_details">
        <span class="overline">Application Details</span>
        <p v-if="applicationDetails.status !== 'ERROR'">
          <span class="body-2 text--secondary">Status:</span>
          <span> {{ applicationDetails.status }}</span>
          <br>
          <span class="body-2 text--secondary">DBS Reference:</span>
          <span> {{ applicationDetails.form_reference }}</span>
          <br>
          <br>
          <span class="body-2 text--secondary">Received Date:</span>
          <span> {{ applicationDetails.received_date }}</span>
          <template v-if="applicationDetails.police_national_computer_search_date">
            <br>
            <span class="body-2 text--secondary">Police NC Search Date:</span>
            <span> {{ applicationDetails.police_national_computer_search_date }}</span>
          </template>
          <template v-if="applicationDetails.barred_lists_search_date">
            <br>
            <span class="body-2 text--secondary">
              DBS Children, DBS Adults and List 99 Search Date:
            </span>
            <span> {{ applicationDetails.barred_lists_search_date }}</span>
          </template>
          <template v-if="applicationDetails.police_records_held_date">
            <br>
            <span class="body-2 text--secondary">Records Held by the Police Search Date:</span>
            <span> {{ applicationDetails.police_records_held_date }}</span>
          </template>
          <template v-if="applicationDetails.certificate_printed_date">
            <br>
            <span class="body-2 text--secondary">Certificate Printed Date:</span>
            <span> {{ applicationDetails.certificate_printed_date }}</span>
          </template>
          <template v-if="dbsDetails.verified_by">
            <br>
            <span class="body-2 text--secondary">Verified By:</span>
            <span> {{ dbsDetails.verified_by.first_name }}
              {{ dbsDetails.verified_by.last_name }}</span>
          </template>
          <template v-if="applicationDetails.countersignatory">
            <br>
            <span class="body-2 text--secondary">Countersigned By:</span>
            <span>{{ applicationDetails.countersignatory.name }}</span>
          </template>
          <template v-else-if="dbsDetails.countersigned_by">
            <br>
            <span class="body-2 text--secondary">Countersigned By:</span>
            <span> {{ dbsDetails.countersigned_by.first_name }}
              {{ dbsDetails.countersigned_by.last_name }}</span>
          </template>
        </p>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span class="overline">Disclosure Result</span>
        <template v-if="disclosureResult.status">
          <p>
            <span class="body-2 text--secondary">Status:</span><br>
            <span class="font-weight-bold">{{ disclosureResult.status }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary">Number:</span>
            <span> {{ disclosureResult.number }}</span>
            <span v-if="disclosureResult.manual"> (manual certificate)</span>
            <br>
            <span class="body-2 text--secondary">Issue date:</span>
            <span> {{ disclosureResult.issue_date }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary mr-2">Note:</span>
            <span>This is not a certificate issued by the DBS</span>
          </p>
          <p v-if="updateServiceStatus">
            <span class="body-2 text--secondary">Update Service:</span>
            <span> {{ updateServiceStatus }}</span>
          </p>
        </template>
        <template v-else>
          <p>
            <span class="font-italic text--secondary">DBS Results not yet available</span>
          </p>
          <div v-if="canRequestAdultFirst">
            <v-btn
              :disabled="adultFirstRequested"
              @click="requestAdultFirst"
            >
              <v-icon left>
                mdi-run-fast
              </v-icon>
              Request Adult First Check
            </v-btn>
            <br>
            <span v-if="adultFirstRequested && !adultFirstRequestSent">
              Adult First Requested at:
              {{ caseActivityDetails.result_data.adult_first_requested_at | longdatetime }}
            </span>
            <span v-else-if="adultFirstRequestSent">Adult First Requested.</span>
          </div>
        </template>
      </v-col>
    </v-row>
    <p v-else-if="dbsDetails.state === 'pending' && profile.customer.ops_idv">
      This application is awaiting verification by the {{ product.name }} team.
    </p>
    <p v-else-if="dbsDetails.state === 'pending'">
      This application is awaiting verification by the employer.
    </p>
    <p v-else-if="dbsDetails.state === 'verified'">
      This application is awaiting countersigning.
    </p>
    <CaseActivityConfig
      v-if="caseActivityDetails.id"
      :case-activity-details="caseActivityDetails"
    >
      <RequirementConfig
        :case-activity-details="caseActivityDetails"
      />
    </CaseActivityConfig>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CaseActivityConfig from '@/components/product/case_activities/CaseActivityConfig.vue';
import RequirementConfig from '@/components/product/case_activities/ebulk/RequirementConfig.vue';

export default {
  name: 'EbulkDetails',
  components: {
    CaseActivityConfig,
    RequirementConfig,
  },
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dbsDetails: {},
    adultFirstRequestSent: false,
  }),
  computed: {
    ...mapState('ui', ['product']),
    ...mapState('users', ['profile']),
    activityData() {
      return this.caseActivityDetails.result_data;
    },
    applicantName() {
      const details = this.activityData.applicant_details || {};
      return [details.forenames, details.surname].filter(Boolean).join(' ');
    },
    applicationDetails() {
      return this.activityData.application_details || {};
    },
    disclosureResult() {
      return this.activityData.disclosure_result || {};
    },
    updateServiceStatus() {
      const matchStatuses = ['BLANK_NO_NEW_INFO', 'NON_BLANK_NO_NEW_INFO', 'NEW_INFO'];
      const updateService = this.activityData.update_service;
      let status = null;
      if (updateService && matchStatuses.includes(updateService.status)) {
        status = 'Registered';
      } else if (updateService && this.caseActivityDetails.state === 'ca-in-progress') {
        status = 'Pending';
      } else if (updateService) {
        status = 'Not registered';
      }
      return status;
    },
    canRequestAdultFirst() {
      const caseCaseActivies = this.caseActivityDetails?.case?.case_activities;
      return (
        this.caseActivityDetails.activity_type.code === 'dbs-enhanced'
          && this.profile.customer.activity_types.some((at) => at.code === 'dbs-adult-first')
          && !caseCaseActivies.some((ca) => ca.activity_type.code === 'dbs-adult-first')
          && this.profile.permissions.includes('cases.add_case')
          && this.caseActivityDetails.config.adult_barred_list
          && this.caseActivityDetails.config.workforce.includes('A')
      );
    },
    adultFirstRequested() {
      const requestedAt = this.caseActivityDetails.result_data.adult_first_requested_at;
      return Boolean(requestedAt) || this.adultFirstRequestSent;
    },
  },
  created() {
    this.getDbsDetails();
  },
  methods: {
    async getDbsDetails() {
      const resp = await this.$service.product.caseDBSDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.dbsDetails = resp.data;
      }
      return null;
    },
    async requestAdultFirst() {
      const resp = await this.$service.product.caseActivities.requestAdultFirst(
        this.caseActivityDetails.id,
      );
      if (resp.status === 200) {
        this.adultFirstRequestSent = true;
      }
    },
  },
};
</script>

<template>
  <div>
    <p v-if="caseActivityDetails.result_data.manual_idv_approved">
      Online ID verification could not be completed and manual verification was approved.
    </p>
    <v-list v-else-if="caseActivityDetails.result_data.id_profile">
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.first_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="onlineIdvAssertion.current_name.middle_name">
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.middle_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssertion.current_name.family_name }}
              </v-list-item-title>
              <v-list-item-subtitle>Surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ parseIsoDate(onlineIdvAssertion.date_of_birth) }}
              </v-list-item-title>
              <v-list-item-subtitle>Date of birth</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvCurrentAddres }}
              </v-list-item-title>
              <v-list-item-subtitle>Current address</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ onlineIdvAssuranceLevel }}
              </v-list-item-title>
              <v-list-item-subtitle>Level of assurance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>
import { parseYotiAddress } from '@/util/yoti';

export default {
  name: 'OnlineIdv',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    onlineIdvAssertion() {
      if (this.caseActivityDetails.result_data.id_profile) {
        return this.caseActivityDetails.result_data.id_profile.identity_assertion;
      }
      return null;
    },
    onlineIdvCurrentAddres() {
      if (this.onlineIdvAssertion) {
        return this.getFullAddress(
          parseYotiAddress(this.onlineIdvAssertion.current_address.address),
        );
      }
      return null;
    },
    onlineIdvAssuranceLevel() {
      if (this.caseActivityDetails.result_data.id_profile) {
        const verifReport = this.caseActivityDetails.result_data.id_profile.verification_report;
        return verifReport.assurance_process.level_of_assurance;
      }
      return null;
    },
  },
  methods: {
    getFullAddress(item) {
      return [
        item.line_one_dbs || item.line_one,
        item.line_two_dbs || item.line_two,
        item.town_dbs || item.town,
        item.postcode,
      ].filter(Boolean).join(', ');
    },
    parseIsoDate(val) {
      const date = new Date(
        Date.UTC(val.substring(0, 4), val.substring(5, 7) - 1, val.substring(8, 10)),
      );
      return date.toUTCString().substring(4, 17);
    },
  },
};
</script>

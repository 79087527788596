const licenceNumberValidator = (val, subject) => {
  if (/^\d{8}$/.test(val)) {
    // Northern Ireland licence
    return true;
  }

  const formatSurname = (s) => s.replace(/[^A-Za-z]/g, '').slice(0, 5).padEnd(5, '9').toUpperCase();

  let expectedSurname = formatSurname(subject.surname);
  let expectedInitials = subject.forename.charAt(0) + (subject.middlename || '9').charAt(0);

  if (subject.no_surname) {
    expectedSurname = formatSurname(subject.forename);
    expectedInitials = '99';
  } else if (expectedSurname.startsWith('MAC')) {
    expectedSurname = formatSurname(subject.surname.toUpperCase().replace('MAC', 'MC'));
  }

  const dob = subject.dob || '0000-00-00';
  const expectedMaleDob = dob.charAt(2) + dob.slice(5, 7) + dob.slice(8, 10) + dob.charAt(3);
  const expectedFemaleDob = (
    dob.charAt(2) + (parseInt(dob.charAt(5), 10) + 5) + dob.charAt(6) + dob.slice(8, 10)
    + dob.charAt(3)
  );

  if (expectedSurname.toUpperCase() !== val.slice(0, 5).toUpperCase()) {
    return 'Surname mismatch';
  }

  if (expectedMaleDob !== val.slice(5, 11) && expectedFemaleDob !== val.slice(5, 11)) {
    return 'Date of birth mismatch';
  }

  if (!subject.no_surname && expectedInitials.toUpperCase() !== val.slice(11, 13).toUpperCase()) {
    return 'Forename/middlename initials mismatch';
  }

  if (val.length < 16) {
    return 'Missing digits';
  }

  return val.length <= 18 || 'Too long';
};

const passwordValidator = (val, minLength, upperCase, specialChar) => {
  if (upperCase && (val === val.toLowerCase() || val === val.toUpperCase())) {
    return 'Must contain both uppercase and lowercase letters';
  }
  if (specialChar && !/\W/.test(val)) {
    return 'Must contain special characters, such as punctuation or symbols';
  }
  return val.length >= minLength || `Must be at least ${minLength} characters`;
};

const ukPostcodeValidator = (val) => {
  if (val === 'GIR 0AA') {
    return true;
  }
  if (!/^[A-Z]{1,2}[0-9][A-Z0-9]?\s{1}?[0-9][A-Z]{2}$/.test(val)) {
    return 'Invalid format';
  }
  const firstPartInvalidChars = [
    ['Q', 'V', 'X'],
    ['I', 'J', 'Z'],
    ['I', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'V', 'X', 'Y', 'Z'],
    ['C', 'D', 'F', 'G', 'I', 'J', 'K', 'L', 'O', 'Q', 'S', 'T', 'U', 'Z'],
  ];
  const secondPartInvalidChars = ['C', 'I', 'K', 'M', 'O', 'V'];
  const [firstPart, secondPart] = val.split(' ');
  let valid = true;
  firstPartInvalidChars.forEach((chars, index) => {
    if (firstPart.length > index && chars.includes(firstPart[index])) {
      valid = false;
    }
  });
  Array.from(secondPart.slice(-2)).forEach((char) => {
    if (secondPartInvalidChars.includes(char)) {
      valid = false;
    }
  });
  return valid || 'Invalid format';
};

export {
  licenceNumberValidator,
  passwordValidator,
  ukPostcodeValidator,
};
